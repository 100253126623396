import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useRecaptcha } from "hooks/useRecaptcha";
import { useSession } from "hooks/useSession";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FaFacebook, FaLine } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import appStore from "../store";
import api from "../store/api";

export default function Login() {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { locale } = router;
  const session = useSession();
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("886");
  const [countryName, setCountryName] = useState("tw");
  const [usAgree, setUsAgree] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    token: reCaptchaToken,
    reset: resetRecaptchaToken,
    execute: executeRecaptcha,
  } = useRecaptcha();

  useEffect(() => {
    if (locale === "jp") {
      setCountryCode("81");
      setCountryName("jp");
    }
  }, [locale]);

  useEffect(() => {
    if (router.query?.phone) {
      setPhone(router.query?.phone);
    }
  }, [router.isReady]);

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    }
  }, [countDown]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let phoneNumber = phone.replace(`${countryCode}`, "");

    if (countryCode === "886" && phoneNumber.length === 9) {
      phoneNumber = phone.replace(`${countryCode}`, "0");
    }
    if (countryCode === "81" && phoneNumber.length === 10) {
      phoneNumber = phone.replace(`${countryCode}`, "0");
    }

    api
      .getPassCode({
        google_recaptcha_token: reCaptchaToken,
        country_code: countryCode,
        phone_number: phoneNumber,
        verify_type: "invisible",
        country_name: countryName,
      })
      .then(() => {
        setCountDown(120);
      })
      .catch((error) => {
        const errorCode = error?.message;
        switch (errorCode) {
          case "smsCoolDown":
            const seconds = error.cooldown;
            alert(`${t("phoneError1-1")}${seconds}${t("phoneError1-2")}`);
            return;
          case "smsPhoneNumberError":
            alert(t("phoneError2"));
            return;
          default:
            alert(t("phoneError3"));
            break;
        }
      });

    resetRecaptchaToken();
    executeRecaptcha();
  };

  return (
    <Flex direction="column" align="center" py="32px">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("contactUs")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody color="#363740" pb="20px">
            <Flex wordBreak="break-word">
              <Box mr="6px">
                <FaFacebook />
              </Box>
              <Text wordBreak="normal" fontSize="14px" mr="5px">
                Facebook:
              </Text>
              <Text
                fontSize="14px"
                color="blue"
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/messages/t/110275088542939/"
                  );
                }}
              >
                https://www.facebook.com/messages/t/110275088542939/
              </Text>
            </Flex>
            <Flex wordBreak="break-word" mt="10px">
              <Box mr="6px">
                <FaLine />
              </Box>
              <Text fontSize="14px" mr="5px">
                LINE@:
              </Text>
              <Text
                as="span"
                fontSize="14px"
                color="blue"
                onClick={() => {
                  window.open("https://lin.ee/3BR2kM7");
                }}
              >
                https://lin.ee/3BR2kM7
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex
        direction="column"
        align="center"
        px="20px"
        w={{ base: "100%", md: "75%" }}
      >
        <Box
          boxShadow="0 10px 30px 0 rgba(0, 0, 0, .1)"
          p="20px"
          pb="35px"
          borderRadius="10px"
        >
          <Text fontSize="20px" textAlign="center" mb="10px">
            {t("usePhoneNumLogin")}
          </Text>
          {router?.query?.helper && (
            <HStack mb="20px" align="end">
              <Text fontSize="24px" lineHeight="1">
                💁‍♀️
              </Text>
              <Text
                bgColor="#3374F6"
                fontSize="14px"
                color="#FFF"
                fontWeight="bold"
                rounded="8px"
                lineHeight="1.1"
                pos="relative"
                p="10px"
                _before={{
                  content: '""',
                  position: "absolute",
                  bottom: "9px",
                  left: "-5px",
                  width: "0",
                  height: "0",
                  borderTop: "5px solid transparent",
                  borderBottom: "5px solid transparent",
                  borderRight: "6px solid #3374F6",
                }}
              >
                {t("uchat_login_tips")}
              </Text>
            </HStack>
          )}
          <FormControl id="phone_number" isRequired mb="10px">
            <FormLabel>{t("phone")}</FormLabel>
            <Flex>
              <PhoneInput
                inputStyle={{ height: "100%", width: "100%" }}
                enableSearch={true}
                countryCodeEditable={false}
                autoFormat={true}
                country={locale === "jp" ? "jp" : "tw"}
                value={countryCode}
                masks={{
                  tw: "....-...-...",
                  jp: "...-....-....",
                }}
                onChange={(phone, code) => {
                  setPhone(phone);
                  setCountryCode(code.dialCode);
                  setCountryName(code.countryCode);
                }}
              />
              <Button
                disabled={
                  countDown > 0 ||
                  phone.replace(`${countryCode}`, "") === "" ||
                  countryCode === "1"
                    ? !usAgree
                    : false
                }
                type="submit"
                ml="5px"
                fontSize="12px"
                onClick={handleSubmit}
              >
                {t("getPassCode")}
              </Button>
            </Flex>
            {countDown > 0 && (
              <Flex justify="space-between" mt="8px">
                <Text fontSize="12px" opacity="0.5">
                  {countDown} {t("Login_infotext_verificationCode")}
                </Text>
                <Text
                  fontSize="12px"
                  opacity="0.5"
                  cursor="pointer"
                  onClick={onOpen}
                >
                  {t("Login_btn_verificationCode")}
                </Text>
              </Flex>
            )}

            {countryCode === "1" && (
              <Checkbox
                mt="8px"
                display="flex"
                wordBreak="break-word"
                onChange={(e) => {
                  setUsAgree(e.target.checked);
                }}
              >
                <Text ml="8px" w="280px">
                  I would like to receive SMS notifications to the phone number
                  provided about login, order status info. Message and data
                  rates may apply. Reply STOP to cancel.
                  <Link href="https://uspace.city/app/usage/#en">
                    <a style={{ color: "blue" }} target="_blank">
                      {" "}
                      Terms of Service
                    </a>
                  </Link>
                </Text>
              </Checkbox>
            )}
          </FormControl>
          <FormControl id="phone_number">
            <FormLabel>{t("passCode")}</FormLabel>
            <HStack>
              <Input
                onWheel={(e) => e.target.blur()}
                placeholder={t("inputPassCode")}
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                maxLength={4}
                onChange={(e) => {
                  if (e.target?.value?.length === 4 && phone) {
                    const passCode = e.target?.value;
                    let phoneNumber = phone.replace(`${countryCode}`, "");
                    if (countryCode === "886" && phoneNumber.length === 9) {
                      phoneNumber = phone.replace(`${countryCode}`, "0");
                    }
                    if (countryCode === "81" && phoneNumber.length === 10) {
                      phoneNumber = phone.replace(`${countryCode}`, "0");
                    }

                    const promotion = { ...router.query };
                    delete promotion["group"];
                    delete promotion["callback_url"];
                    delete promotion["order_id"];
                    const registrationSource = JSON.stringify(promotion);
                    api
                      .getToken({
                        phoneNumber,
                        passCode,
                        countryCode,
                        registrationSource,
                      })
                      .then((result) => {
                        if (result.status === 200) {
                          session.setToken(result.data.token);
                          api
                            .getMyProfile(result.data.token)
                            .then((resp) => {
                              if (resp.status === 200) {
                                appStore.member = resp.data.content;
                                router.push({
                                  pathname:
                                    router?.query?.callback_url ||
                                    "/account/rental_orders",
                                  query: router.query,
                                });
                              }
                            })
                            .catch(console.log);
                        }
                      })
                      .catch(console.log);
                  }
                }}
              />
            </HStack>
          </FormControl>
          <VStack fontSize="14px" w="100%" mt="35px">
            <HStack justify="center">
              <CheckIcon color="#2CD165" />
              <Text w="230px" textAlign="left" opacity={true ? 1 : 0.5}>
                {t("discountContent1")}
              </Text>
            </HStack>
            <HStack mt="30px" justify="center">
              <CheckIcon color="#2CD165" />
              <Text w="230px" textAlign="left" opacity={true ? 1 : 0.5}>
                {t("discountContent2")}
              </Text>
            </HStack>
            <HStack mt="30px" justify="center">
              <CheckIcon color="#2CD165" />
              <Text w="230px" textAlign="left" opacity={true ? 1 : 0.5}>
                {t("discountContent3")}
              </Text>
            </HStack>
          </VStack>
        </Box>
      </Flex>
    </Flex>
  );
}

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}
